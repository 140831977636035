<template>
  <div id="dashboard">
    <h1 style="text-align: center; height: 100vh; line-height: 100vh">
      欢迎使用短信发送平台
    </h1>
  </div>
</template>

<script>
export default {
  name: "dashboard",
};
</script>

<script setup>
// import { reactive, ref, onBeforeMount, onMounted, watch, onActivated } from "vue";
// import { useI18n } from 'vue-i18n'
// const { t } = useI18n()
</script>

<style lang="scss" scoped>
#dashboard {
  height: 100vh;
}
</style>
